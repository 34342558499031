<template>
  <base-widget title="DEALERS">
    <v-card-text class="text-center">
      <v-avatar
        class="mb-3 elevation-8"
        color="cyan darken-2"
        size="130"
      >
        <v-icon size="95">mdi-account</v-icon>
      </v-avatar>

      <h2 class="headline mb-2">
        {{totalDealers}}
      </h2>

    </v-card-text>


  </base-widget>
</template>

<script>
  export default {
    name: 'WidgetTotalDealers',
    computed: {
      totalDealers(){
        return this.$store.state.totalDealers
      },
    }
  }
</script>
